.videos-slider:hover {
  z-index: 2;
}

.slick-slider {
  position: relative;
}

.slick-list {
  overflow: visible !important;
}

div.slick-track {
  margin: auto!important;
  display: flex;
  &:before,
  &:after {
    content: close-quote;
    display: none;
  }
}

div.slick-slide {
  box-sizing: border-box;
  padding-right: 10px;
  transition: transform 300ms ease 100ms;
  float: none;
  height: inherit;
  & > div,
  img,
  .--fullHeight {
    height: 100%;
  }
  & img {
    object-fit: cover;
  }
}

.visible {
  position: relative;
}

.visible.hovered {
  position: relative;
  transition: transform 300ms ease 100ms;
}

.visible-first.hovered,
.visible-last.hovered {
  transition: transform 300ms ease 100ms;
}

.visible.hovered:hover {
  transform: scale(1.1) !important;
}

.visible-first.hovered:hover {
  transform: translateX(5%) scale(1.1) !important;
}

.visible-first.withArrow.hovered:hover {
  transform: translateX(100px) scale(1.1) !important;
}

.visible-last.hovered:hover {
  transform: translateX(-5%) scale(1.1) !important;
}

.visible-last.withArrow.hovered:hover {
  transform: translateX(-100px) scale(1.1) !important;
}

.hovered {
  z-index: 2;
}

div.slick-track {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    &:before,
    &:after {
        content: close-quote;
        display: none;
    }
}
div.slick-slide {
    box-sizing: border-box;
    padding-right: 10px;
    transition: transform 300ms ease 100ms;
    float: none;
    height: inherit;
    & img {
        height: 100%;
        object-fit: cover;
    }
}
