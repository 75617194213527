.slick-prev:before, .slick-next:before  {
    content: '' !important;
}

.slick-disabled {
    display: none!important;
}

.slick-prev, .slick-next {
    background: rgba(0, 0, 0, 0.5)!important;
    height: 100%!important;
    width: 100px!important;
    z-index: 100!important;
}

.icon {
    color: white;
    font-size: 100px!important;
}

.icon.right {
    transform: rotate(-90deg);
}

.icon.left {
    transform: rotate(90deg);
}
