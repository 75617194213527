.ps__rail-x,
.ps__rail-y {
  z-index: 10000;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #1f1f1f;
}

::-webkit-scrollbar-thumb {
  background-color: #5f5f5f;
  border: solid #1f1f1f 4px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #7f7f7f;
}
