body {
  background: #000;
  margin: 0;
  /*position: relative;*/
  /*overflow: hidden;*/
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  /*overflow-x: hidden;*/
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
