.navBarLink {
  -webkit-user-drag: none;
  transition: 0.3s all;
}

.navBarLink:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.navBarTabs:before {
  content: '';
  position: absolute;
  top: -10px;
  left: 0;
  height: 10px;
  width: 100%;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.84);
  z-index: 1;
}

.linkTabWrapper--active:before {
  content: '';
  position: absolute;
  height: 21px;
  width: 21px;
  top: 0;
  left: -21px;
  background-color: black;
}

.linkTabWrapper--active:after {
  content: '';
  position: absolute;
  height: 21px;
  width: 21px;
  top: 0;
  right: -21px;
  background-color: black;
}

.tabIndicator {
  position: relative;
  z-index: 2;
}

.tabIndicator:after {
  position: absolute;
  top: 4px;
  left: -20px;
  content: '';
  height: 20px;
  width: 20px;
  -webkit-filter: drop-shadow(-3px 0px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(-3px 0px 2px rgba(0, 0, 0, 0.7));
  background-image: url('../../../assets/tabCorner.svg');
  background-size: 20px 20px;
}

.tabIndicator:before {
  position: absolute;
  top: 4px;
  right: -20px;
  content: '';
  height: 20px;
  width: 20px;
  transform: scaleX(-1);
  -webkit-filter: drop-shadow(-3px 0px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(-3px 0px 2px rgba(0, 0, 0, 0.7));
  background-image: url('../../../assets/tabCorner.svg');
  background-size: 20px 20px;
  /*z-index: -1;*/
}
