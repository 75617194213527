@keyframes initialFadeIn {
  from {
    background-color: #10101055;
  }
  to {
    background-color: #101010e5;
  }
}

@keyframes containerOpacity {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
